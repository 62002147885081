.title-mapa-img1 {
    margin-top: 2rem;
}

.title-mapa-img2 {
    margin-top: 1rem;
}

.section-mapa {
    display: grid;
    justify-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

@media (max-width: 1024px) {



}